import { availableLocales } from 'config/availableLocales';
import { defineConfig } from 'features/featureFlag/defineConfig';

const debugFeatureFlags = defineConfig({
  debugDivider: {
    type: 'divider',
    label: 'Debug Tools',
  },
  'show-feature-flag-icon': {
    label: 'Show feature flag icon',
    type: 'toggle',
  },
  'quick-i18n': {
    label: 'Quick switch language',
    type: 'singleSelect',
    options: availableLocales.map(({ code, displayName }) => ({ label: displayName, value: code })),
  },
  'enabled-server-region': {
    label: 'Update server region',
    type: 'singleSelect',
    options: [
      {
        value: 'global',
        label: 'Global',
      },
      {
        value: 'jp',
        label: 'Japan',
      },
    ],
  },
  'react-query-devtool': {
    label: 'Enable react-query devtool',
    type: 'singleSelect',
    options: [
      {
        value: 'bottom-right',
        label: 'Bottom right',
      },
      {
        value: 'top-left',
        label: 'Top left',
      },
      {
        value: 'top-right',
        label: 'Top right',
      },
      {
        value: 'bottom-left',
        label: 'Bottom left',
      },
    ],
  },
});

const releasedFeatureFlags = defineConfig({
  releasedFeatures: {
    type: 'divider',
    label: 'Released Features',
  },
  'auto-segment': { type: 'toggle' },
  'integration-pages': { type: 'toggle' },
  'show-task-key': { label: 'Show task key in TaskProgressModal', type: 'toggle' },
  'show-pushed-tables-card': {
    label:
      'Show auto-reply and tag tables in Insights/Engagement. Show total used point in Insights/Notification Message',
    type: 'toggle',
  },
  'show-journey-v2-scheduled-status': {
    label: 'Show Customer Journey V2 Scheduled Status',
    type: 'toggle',
  },
  'show-journey-cdp-tag-trigger': {
    label: 'Show Journey CDP/CDH tag trigger',
    type: 'toggle',
  },
  'show-new-line-message-editor': {
    label: 'Show new Line message editor (for developing new editor)',
    type: 'toggle',
  },
  'activate-api-client': {
    label: 'Use new typesafe API client for data fetching',
    type: 'toggle',
  },
  'enable-table-view-v2': {
    label: 'Show table view V2 components',
    type: 'toggle',
  },
  'show-line-message-editor-notification': {
    label: 'Show Line message notification',
    type: 'toggle',
  },
  'show-setting-in-card-and-carousel-modules': {
    label: 'Show setting in card and carousel modules',
    type: 'toggle',
  },
  vitally: { label: 'Show Vitally NPS', type: 'toggle' },
});

export const featureFlagConfigs = defineConfig({
  ...debugFeatureFlags,
  ...releasedFeatureFlags,
});
