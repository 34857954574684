import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { ListResponseSchema } from 'shared/models/common';
import { ISO8601DateSchema } from 'shared/models/common/date';
import { JourneyItemStatusEnum, JourneyItemStatusSchema } from 'shared/models/journey';
import { JourneyEdgeSchema } from 'shared/models/journey/edge';
import { JourneyNodeSchema } from 'shared/models/journey/node';
import { JourneyNodeTypeSchema } from 'shared/models/journey/nodeCommon';
import { JourneyNodeUpdateParametersSchema } from 'shared/models/journey/nodeMutations';

const JourneyListReportDataSchema = z.object({
  trigger: z.number().nullable(),
  in_progress: z.number().nullable(),
  exit: z.number().nullable(),
  transaction_revenue: z.number().nullable(),
});

/**
 * The standard journey item
 */
const JourneyItemBaseSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  status: JourneyItemStatusSchema,
  repeat_journey_times: z.number().nullable(),
  line_message_quota: z.number().nullable(),
  sleep_start_time: z.string().nullable(),
  sleep_end_time: z.string().nullable(),
  schedule_start_at: ISO8601DateSchema.nullable(),
  schedule_end_at: ISO8601DateSchema.nullable(),
  created_at: ISO8601DateSchema,
  updated_at: ISO8601DateSchema,
});

/**
 * A journey item with attached workflow data; used by both the form and report
 */
const JourneyItemSchema = JourneyItemBaseSchema.merge(
  z.object({
    bot_id: z.number().int(),
    nodes: z.array(JourneyNodeSchema),
    paths: z.array(JourneyEdgeSchema),
  }),
);

const JourneyReportSchema = z.object({
  message_sent: z.number().nullable(),
  open_count: z.number().nullable(),
  clicks: z.number().nullable(),
  unique_clicks: z.number().nullable(),
  adds_to_cart: z.number().nullable(),
  transactions: z.number().nullable(),
  transaction_revenue: z.number().nullable(),
  clicks_of_share: z.number().nullable(),
  unique_clicks_of_share: z.number().nullable(),
  last_updated_at: ISO8601DateSchema.nullable(),
});

const JourneyCreateParameters = z.object({
  bot_id: z.number().int(),
  name: z.string(),
  schedule_start_at: z.string().nullable().optional(),
  schedule_end_at: z.string().nullable().optional(),
  repeat_journey_times: z.number().int().nullable().optional(),
  line_message_quota: z.number().int().nullable().optional(),
  sleep_start_time: z.string().nullable().optional(),
  sleep_end_time: z.string().nullable().optional(),
  source_journey_id: z.number().int().optional(),
});

export const journeyApi = makeApi([
  {
    alias: 'journeyList',
    method: 'get',
    path: '/journey/v1/journey/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: JourneyItemStatusSchema.optional(),
        type: 'Query',
      },
      {
        name: 'name',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: ListResponseSchema.extend({
      results: JourneyItemBaseSchema.extend({
        report: JourneyListReportDataSchema,
      })
        .strict()
        .array(),
    }),
  },
  {
    alias: 'journeyListMetrics',
    method: 'get',
    path: '/journey/v1/journey/count/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: JourneyItemStatusSchema.optional(),
        type: 'Query',
      },
      {
        name: 'name',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        count: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'journeyDetail',
    method: 'get',
    path: '/journey/v1/journey/:journeyItemId/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyReport',
    method: 'get',
    path: '/journey/v1/journey/:journeyItemId/report/',
    parameters: [
      {
        name: 'start_date',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'end_date',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: JourneyReportSchema.strict(),
  },
  {
    alias: 'journeyItemCreate',
    method: 'post',
    path: '/journey/v1/journey/',
    parameters: [
      {
        name: 'body',
        schema: JourneyCreateParameters.strict(),
        type: 'Body',
      },
    ],
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyItemUpdate',
    method: 'patch',
    path: '/journey/v1/journey/:journeyItemId/',
    parameters: [
      {
        name: 'body',
        schema: JourneyCreateParameters.strict(),
        type: 'Body',
      },
    ],
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyItemDelete',
    method: 'delete',
    path: '/journey/v1/journey/:journeyItemId/',
    response: z.never(),
  },
  {
    alias: 'journeyItemPublish',
    method: 'post',
    path: '/journey/v1/journey/:journeyItemId/publish/',
    response: JourneyItemSchema.strict(),
  },
  {
    alias: 'journeyItemTurnOn',
    method: 'post',
    path: '/journey/v1/journey/:journeyItemId/on/',
    response: JourneyItemSchema.omit({
      status: true,
    })
      .extend({
        status: z.enum([JourneyItemStatusEnum.ongoing, JourneyItemStatusEnum.scheduled]),
      })
      .strict(),
  },
  {
    alias: 'journeyItemPause',
    method: 'post',
    path: '/journey/v1/journey/:journeyItemId/pause/',
    response: JourneyItemSchema.omit({
      status: true,
    })
      .extend({
        status: z.literal(JourneyItemStatusEnum.paused),
      })
      .strict(),
  },
  {
    alias: 'journeyNodeCreate',
    method: 'post',
    path: '/journey/v1/node/',
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            journey_id: z.number().int(),
            path_ids: z.number().int().array(),
            type: JourneyNodeTypeSchema,
          })
          .strict(),
        type: 'Body',
      },
    ],
    response: JourneyNodeSchema,
  },
  {
    alias: 'journeyNodeUpdate',
    method: 'patch',
    path: '/journey/v1/node/:journeyNodeId/',
    parameters: [
      {
        name: 'body',
        // Note: we can't use strict method on a discriminated union
        schema: JourneyNodeUpdateParametersSchema,
        type: 'Body',
      },
    ],
    response: JourneyNodeSchema,
  },
  {
    alias: 'journeyNodeDelete',
    method: 'delete',
    path: '/journey/v1/node/:journeyNodeId/',
    response: z.never(),
  },
]);

export type JourneyDetailResponse = ZodiosResponseByAlias<typeof journeyApi, 'journeyDetail'>;
