import { z } from 'zod';

export const BroadcastStatusEnum = {
  draft: 'draft',
  scheduled: 'scheduled',
  sending: 'sending',
  sent: 'sent',
  fail: 'fail',
} as const;

export const BroadcastStatusSchema = z.nativeEnum(BroadcastStatusEnum);

export type BroadcastStatus = z.output<typeof BroadcastStatusSchema>;

export const BroadcastChannelEnum = {
  line: 'line',
  sms: 'sms',
} as const;

const BroadcastChannelSchema = z.nativeEnum(BroadcastChannelEnum);

export type BroadcastChannel = z.output<typeof BroadcastChannelSchema>;

export const BroadcastFilterEnum = {
  ...BroadcastStatusEnum,
  all: 'all',
  search: 'search',
} as const;

const BroadcastFilterSchema = z.nativeEnum(BroadcastFilterEnum);

export type BroadcastFilterType = z.output<typeof BroadcastFilterSchema>;
