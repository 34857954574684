import { z } from 'zod';

import {
  JourneyNodeTypeEmptySchema,
  JourneyNodeTypeExitSchema,
  JourneyNodeTypeGaAddToCartSchema,
  JourneyNodeTypeGaPageViewSchema,
  JourneyNodeTypeGaPurchaseSchema,
  JourneyNodeTypeLineMessageOpenedSchema,
  JourneyNodeTypeSendLineMessageSchema,
  JourneyNodeTypeTagAddedSchema,
  JourneyNodeTypeTimeDelaySchema,
  JourneyNodeTypeTimeScheduleSchema,
  JourneyNodeTypeYesNoBranchSchema,
} from 'shared/models/journey/nodeType';

// TODO: surely there is a more elegant way to adjust these schemas?
export const JourneyNodeUpdateParametersSchema = z.discriminatedUnion('type', [
  JourneyNodeTypeEmptySchema.omit({ category: true }).partial().required({ type: true }).strict(),
  JourneyNodeTypeTagAddedSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeGaPageViewSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeGaPurchaseSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeGaAddToCartSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeLineMessageOpenedSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeExitSchema.omit({ category: true }).partial().required({ type: true }).strict(),
  JourneyNodeTypeTimeDelaySchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeTimeScheduleSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeYesNoBranchSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
  JourneyNodeTypeSendLineMessageSchema.omit({ category: true })
    .partial()
    .required({ type: true })
    .strict(),
]);

export type JourneyNodeUpdateParameters = z.input<typeof JourneyNodeUpdateParametersSchema>;
