import { z } from 'zod';

export const JourneyItemStatusEnum = {
  draft: 'draft',
  paused: 'paused',
  scheduled: 'scheduled',
  ongoing: 'ongoing',
  ended: 'ended',
} as const;

export const JourneyItemStatusSchema = z.nativeEnum(JourneyItemStatusEnum);

export type JourneyItemStatus = z.output<typeof JourneyItemStatusSchema>;

export const JourneyListFilterEnum = {
  ...JourneyItemStatusEnum,
  all: 'all',
} as const;

const JourneyListFilterSchema = z.nativeEnum(JourneyListFilterEnum);

export type JourneyListFilter = z.output<typeof JourneyListFilterSchema>;
