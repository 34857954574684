import { z } from 'zod';

const CdpDataSourceEnum = {
  maac: 'maac',
  caac: 'caac',
} as const;

export const CdpDataSourceSchema = z.nativeEnum(CdpDataSourceEnum);

export type CdpDataSource = z.output<typeof CdpDataSourceSchema>;

export type CdpTagUniqueId = `${CdpDataSource}-${number}`;
