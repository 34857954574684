import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { EditorMessagePayloadSchema } from 'shared/models/editor/message';

export const testerApi = makeApi([
  {
    alias: 'testerMessageCreate',
    method: 'post',
    path: '/line/v2/tester/message/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          bot_id: z.number().int(),
          type: z.enum([
            'broadcast',
            'webhook_trigger',
            'guide_link',
            'retargeting',
            'dpm',
            'openapi',
          ]),
          messages: EditorMessagePayloadSchema.shape.messages,
          memberIds: z.array(z.number().int()).optional(),
          param_placeholder: z.record(z.string()),
        }),
      },
    ],
    response: z.union([
      z.object({
        batch: z.number().int(),
        error_msg: z.array(z.string()),
        send_count: z.number().int(),
        test_message_count: z.number().int(),
        tester_count: z.number().int(),
      }),
      z.object({
        error: z.string(),
      }),
    ]),
  },
]);
