import { createVarCall } from '@chatbotgang/motif';
import { Flex, Space } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'zod';

import type { ButtonProps } from 'antd';
import type { FC } from 'react';
import type {
  LineMessageEditorInput,
  Message,
} from 'shared/components/Editor/LineMessageEditor/types';
import type { CustomElementTypes } from 'shared/components/Editor/types/slate-custom';

import { useHandler } from 'hooks/useEventCallback';
import { useToggle } from 'hooks/useToggle';
import { MotifIcon } from 'icons/motif';
import { Button } from 'shared/components/Button';
import { LineMessageEditorPhoneChatRoomPreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPhoneChatRoomPreview';
import { LineMessageEditorPhoneNotificationPreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPhoneNotificationPreview';
import { LineMessageEditorPreviewContent } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPreviewContent';
import { LineMessageEditorPreviewWrapper } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPreviewWrapper';
import { LineMessageEditorTextModulePreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorTextModulePreview';
import { useShowLineMessageEditorNotification } from 'shared/components/Editor/LineMessageEditor/hooks/useShowLineMessageEditorNotification';
import { getColorByTagType } from 'shared/components/Editor/LineMessageEditor/utils/getColorByTagType';
import {
  isCardModule,
  isCarouselModule,
  isFlexModule,
  isImageCarouselModule,
  isImagemapCarouselModule,
  isImagemapModule,
  isImageModule,
  isNewsModule,
  isPrizeModule,
  isTextModule,
  isVideoModule,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { Tabs } from 'shared/components/Tabs';
import { Typography } from 'shared/components/Typography';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

const INITIAL_HEIGHT = 82;

const SegmentButtonActionEnum = {
  CHAT_ROOM: 'chatRoom',
  NOTIFICATION: 'notification',
} as const;

const SegmentButtonActionSchema = z.nativeEnum(SegmentButtonActionEnum);

type SegmentButtonAction = z.output<typeof SegmentButtonActionSchema>;

interface TagProps {
  type: CustomElementTypes;
}

const Tag = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['type']),
})<TagProps>`
  color: ${({ type }) => getColorByTagType(type)};
  cursor: pointer;
`;

const NotificationWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 16px 11px;
  background-color: ${theme.colors.white001};
  opacity: 0.9;
  border: solid 1px ${theme.colors.neutral003};
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  overflow-wrap: break-word;
  min-height: 82px;
`;

interface SegmentedButtonProps extends ButtonProps {
  active?: boolean;
}

const SegmentedButton = styled(Button).withConfig({
  shouldForwardProp: shouldNotForwardProps(['active']),
})<SegmentedButtonProps>`
  ${({ active }) =>
    active
      ? `
  background-color: ${createVarCall('--static-bg-primary')};
  color: ${createVarCall('--static-fg-primary')};
`
      : `
  color: ${createVarCall('--static-fg-inactive')};
`};
`;

export interface LineMessageEditorPreviewProps {
  messages: LineMessageEditorInput['messages'];
  quickReply?: LineMessageEditorInput['quick_reply'];
  headingMessage?: React.ReactNode;
  fullWidthTab?: boolean;
  switcher?: 'tabs' | 'button';
}

export const LineMessageEditorPreview: FC<LineMessageEditorPreviewProps> =
  function LineMessageEditorPreview({
    messages = [],
    quickReply = { items: [] },
    headingMessage,
    fullWidthTab = false,
    switcher = 'tabs',
  }) {
    const { t } = useTranslation();

    const showLineMessageEditorNotificationTabs = useShowLineMessageEditorNotification();

    const firstMessage = messages.at(0);
    const lastMessage = messages.at(-1);

    const innerLayerRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(INITIAL_HEIGHT);
    const { active: notificationOpen, toggle: setNotificationOpen } = useToggle(false);

    const handleNotificationClick = () => {
      if (!innerLayerRef.current) return;

      const newHeight = innerLayerRef.current.offsetHeight + 30;
      if (notificationOpen || newHeight <= height) {
        setHeight(INITIAL_HEIGHT);
      } else {
        setHeight(newHeight);
      }

      setNotificationOpen();
    };

    const [activeKey, setActiveKey] = useState<SegmentButtonAction>(
      SegmentButtonActionEnum.CHAT_ROOM,
    );

    const getMessageNotification = useMemo(
      () => (message: Message) => {
        if (isTextModule(message)) {
          return <LineMessageEditorTextModulePreview message={message} />;
        }

        if (isImageModule(message)) {
          return (
            <Trans
              i18nKey="message.notification.image"
              components={{
                Tag: <Tag type="name" />,
              }}
            />
          );
        }

        if (
          isImageCarouselModule(message) ||
          isVideoModule(message) ||
          isImagemapModule(message) ||
          isImagemapCarouselModule(message) ||
          isCardModule(message) ||
          isCarouselModule(message) ||
          isPrizeModule(message) ||
          isNewsModule(message) ||
          isFlexModule(message)
        ) {
          return message.data?.notification_text;
        }

        return null;
      },
      [],
    );

    const items = [
      {
        key: SegmentButtonActionEnum.CHAT_ROOM,
        label: t('lineMessageEditor.preview.chatRoom'),
        children: (
          <LineMessageEditorPreviewWrapper>
            {headingMessage}
            <LineMessageEditorPreviewContent messages={messages} quickReply={quickReply} />
          </LineMessageEditorPreviewWrapper>
        ),
      },
      {
        key: SegmentButtonActionEnum.NOTIFICATION,
        label: t('lineMessageEditor.preview.notification'),
        children: (
          <Space size={20} direction="vertical">
            <LineMessageEditorPhoneNotificationPreview>
              {firstMessage ? getMessageNotification(firstMessage) : ''}
            </LineMessageEditorPhoneNotificationPreview>
            <LineMessageEditorPhoneChatRoomPreview>
              {lastMessage ? getMessageNotification(lastMessage) : ''}
            </LineMessageEditorPhoneChatRoomPreview>
          </Space>
        ),
      },
    ];

    return (
      <Flex
        gap={8}
        vertical={true}
        style={{
          position: 'sticky',
          top: '80px',
          height: 'fit-content',
        }}
      >
        {showLineMessageEditorNotificationTabs ? (
          switcher === 'tabs' ? (
            <Tabs<SegmentButtonAction>
              variant={fullWidthTab ? 'fullWidth' : 'fitting'}
              items={items}
              activeKey={activeKey}
              onTabClick={(activeKey) => setActiveKey(activeKey)}
            />
          ) : (
            <Flex gap={20} vertical={true} align="center">
              <SegmentButtonGroup activeKey={activeKey} setActiveKey={setActiveKey} />
              {activeKey === SegmentButtonActionEnum.CHAT_ROOM ? (
                <LineMessageEditorPreviewWrapper>
                  {headingMessage}
                  <LineMessageEditorPreviewContent messages={messages} quickReply={quickReply} />
                </LineMessageEditorPreviewWrapper>
              ) : null}
              {activeKey === SegmentButtonActionEnum.NOTIFICATION ? (
                <Space size={20} direction="vertical">
                  <LineMessageEditorPhoneNotificationPreview>
                    {firstMessage ? getMessageNotification(firstMessage) : ''}
                  </LineMessageEditorPhoneNotificationPreview>
                  <LineMessageEditorPhoneChatRoomPreview>
                    {lastMessage ? getMessageNotification(lastMessage) : ''}
                  </LineMessageEditorPhoneChatRoomPreview>
                </Space>
              ) : null}
            </Flex>
          )
        ) : null}
        {!showLineMessageEditorNotificationTabs ? (
          <LineMessageEditorPreviewWrapper>
            {firstMessage ? (
              <NotificationWrapper
                style={{ height: notificationOpen ? height : INITIAL_HEIGHT }}
                onClick={handleNotificationClick}
              >
                <div ref={innerLayerRef}>
                  <Flex justify="space-between">
                    <Typography variant="body" fontWeight={700}>
                      {t('message.notificationText')}
                    </Typography>
                    {notificationOpen ? (
                      <MotifIcon un-i-motif="arrow_up" style={{ fontSize: 16 }} />
                    ) : (
                      <MotifIcon un-i-motif="arrow_down" style={{ fontSize: 16 }} />
                    )}
                  </Flex>
                  <Typography variant="body">{getMessageNotification(firstMessage)}</Typography>
                </div>
              </NotificationWrapper>
            ) : null}
            {headingMessage}
            <LineMessageEditorPreviewContent messages={messages} quickReply={quickReply} />
          </LineMessageEditorPreviewWrapper>
        ) : null}
      </Flex>
    );
  };

const SegmentButtonGroup = function SegmentButtonGroup({
  activeKey,
  setActiveKey,
}: {
  activeKey: SegmentButtonAction;
  setActiveKey: (key: SegmentButtonAction) => void;
}) {
  const { t } = useTranslation();

  const elementRefs = useRef<Array<HTMLButtonElement | HTMLAnchorElement | null>>([]);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const widths = elementRefs.current.map((el) => el?.offsetWidth ?? 0);
    setMaxWidth(Math.max(...widths));
  }, []);

  const setRef = useHandler((el: HTMLButtonElement | HTMLAnchorElement | null, index: number) => {
    elementRefs.current[index] = el;
  });

  return (
    <Flex gap={4}>
      <SegmentedButton
        ref={(el) => setRef(el, 0)}
        type="text"
        size="small"
        onClick={() => setActiveKey(SegmentButtonActionEnum.CHAT_ROOM)}
        active={activeKey === SegmentButtonActionEnum.CHAT_ROOM}
        style={{ minWidth: `${maxWidth}px` }}
      >
        <Typography variant="body" fontWeight="medium">
          {t('lineMessageEditor.preview.chatRoom')}
        </Typography>
      </SegmentedButton>
      <SegmentedButton
        ref={(el) => setRef(el, 1)}
        type="text"
        size="small"
        onClick={() => setActiveKey(SegmentButtonActionEnum.NOTIFICATION)}
        active={activeKey === SegmentButtonActionEnum.NOTIFICATION}
        style={{ minWidth: `${maxWidth}px` }}
      >
        <Typography variant="body" fontWeight="medium">
          {t('lineMessageEditor.preview.notification')}
        </Typography>
      </SegmentedButton>
    </Flex>
  );
};
