import { z } from 'zod';

export const JourneyNodeCategoryEnum = {
  trigger: 'trigger',
  rule: 'rule',
  action: 'action',
} as const;

const JourneyNodeCategorySchema = z.nativeEnum(JourneyNodeCategoryEnum);

export type JourneyNodeCategory = z.output<typeof JourneyNodeCategorySchema>;

export const JourneyNodeTriggerTypeEnum = {
  empty: 'empty',
  tag_added: 'tag_added',
  ga_page_view: 'ga_page_view',
  ga_add_to_cart: 'ga_add_to_cart',
  ga_purchase: 'ga_purchase',
  line_message_opened: 'line_message_opened',
} as const;

const JourneyNodeTriggerTypeSchema = z.nativeEnum(JourneyNodeTriggerTypeEnum);

export type JourneyNodeTriggerType = z.output<typeof JourneyNodeTriggerTypeSchema>;

export const JourneyNodeRuleTypeEnum = {
  exit: 'exit',
  time_delay: 'time_delay',
  time_schedule: 'time_schedule',
  yes_no_branch: 'yes_no_branch',
} as const;

const JourneyNodeRuleTypeSchema = z.nativeEnum(JourneyNodeRuleTypeEnum);

export type JourneyNodeRuleType = z.output<typeof JourneyNodeRuleTypeSchema>;

export const JourneyNodeActionTypeEnum = {
  send_line_message: 'send_line_message',
} as const;

const JourneyNodeActionTypeSchema = z.nativeEnum(JourneyNodeActionTypeEnum);

export type JourneyNodeActionType = z.output<typeof JourneyNodeActionTypeSchema>;

export const JourneyNodeTypeSchema = z.nativeEnum({
  ...JourneyNodeTriggerTypeEnum,
  ...JourneyNodeRuleTypeEnum,
  ...JourneyNodeActionTypeEnum,
});

export type JourneyNodeType = z.infer<typeof JourneyNodeTypeSchema>;

export const JourneyNodeOpEnum = z.enum(['with', 'without', 'with_any', 'without_any']);

export const JourneyNodeMatchEnum = z.enum(['all', 'any']);
