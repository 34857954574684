import CheckCircleTwoTone from '@ant-design/icons/CheckCircleTwoTone';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { MouseEventHandler, ReactNode } from 'react';
import type { ModalProps } from 'shared/components/Modal/types';

import { Box } from 'components/layoutUtils';
import { FinishedBar } from 'components/Loading';
import { ProgressBar } from 'components/ProgressBar';
import { Text } from 'components/Typography';
import { Button } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';

export const ExportModal = memo(function ExportModal({
  open,
  title,
  loading,
  finished,
  desc,
  onContinue,
  onCancelBut,
  onOK,
}: Pick<ModalProps, 'open' | 'title'> & {
  loading: boolean;
  finished: boolean;
  desc?: ReactNode;
  onContinue: MouseEventHandler<HTMLElement>;
  onCancelBut: MouseEventHandler<HTMLElement>;
  onOK: MouseEventHandler<HTMLElement>;
}) {
  const { t } = useTranslation();

  return (
    <Modal title={title} footer={null} open={open} onCancel={onOK}>
      <Box m="0 0 16px">{desc}</Box>
      <Box mb="30px">
        {loading ? t('common.asyncReportDescription') : null}
        {!loading && finished ? t('common.asyncReportDescription') : null}
      </Box>
      <Box mb="20px">{loading ? <ProgressBar /> : null}</Box>
      <Box mb="20px">{finished ? <FinishedBar /> : null}</Box>
      {!loading && finished ? (
        <Box mb="30px">
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: '9px' }} />
          <Text>
            <Trans i18nKey="export.finished.fileInNotification" />
          </Text>
        </Box>
      ) : null}
      <Text display="block" textAlign="right">
        {!loading && !finished ? (
          <>
            <Button key="back" style={{ marginRight: '16px' }} onClick={onCancelBut}>
              <Trans i18nKey="common.cancel" />
            </Button>
            <Button key="submit" type="primary" onClick={onContinue}>
              <Trans i18nKey="common.startAsyncExporting" />
            </Button>
          </>
        ) : null}

        {loading || finished ? (
          <Button key="submit" type="primary" onClick={onOK}>
            <span>
              <Trans i18nKey="glossary.ok" />
            </span>
          </Button>
        ) : null}
      </Text>
    </Modal>
  );
});
